import React from 'react';
import Moment from 'react-moment';

class Work extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_0: false,
            show_1: false,
            show_2: false,
            show_3: false,
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = i => {
        this.setState( { ["show_" + (i)] : !this.state["show_"+ (i)] })
    };

    show = i => {
        return this.state["show_" + (i)] ?  "block" : "none"
    };
    status = i => {
        return  !this.state["show_" + (i)] ? "More Details \u00bb" : "\u00ab Less Details";
    }

    render(){
        const details = this.props;
        const _this = this;

            return (
                <div className="work parent">
                    <h4 className="head-90">Experience</h4>
                    {details.work.map(function(job, i) {
                    return ( <div className="row entry" key={i}>
                            <div className="col-lg-12">
                                <h5>{job.company} <span><Moment format="MM/YYYY">{new Date(job.startDate)}</Moment> 
                                    &nbsp;to&nbsp;
                                    {job.endDate !== "Present" ? ( <Moment format="MM/YYYY">{new Date(job.endDate)}</Moment> ) : (job.endDate)}</span></h5>
                                {/* <div className="small-block"><a href={job.website}>{job.website}</a></div> */}
                                <h6>{job.position}</h6>
                                <div>{job.summary}</div>
                                <ul className="m-top highlights" id={"job_" + i} style={{display: _this.show(i) }}>
                                    {job.highlights.map(function(items, index2) {
                                        return (
                                            <li key={index2}>{items}</li>
                                        )
                                    })}
                                </ul>
                                <div className="text-right">
                                    <button className="btn btn-outline-primary btn-sm button-m" type="button" onClick={() => _this.toggle(i)}>
                                        <span>{_this.status(i)}</span>
                                    </button>
                                </div>
                            </div>
                        </div>)
                        })}
                </div>

            )
    }

}

export default Work;
