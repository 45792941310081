import React from 'react';

const Header = (props) => {
    return (
        <header>
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-xs-2 profile"><img src="dan_5.png" alt="Dan Martin" className="rounded-circle" /></div>
                        <div className="col-xs-10 pad-left-15">
                            <h1 className="new-heading">{props.basics.name}</h1>
                            <h2>{props.basics.label}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )

}

export default Header;