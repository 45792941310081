import React from 'react';
import Moment from 'react-moment';

const Education = (props) => {
    return (
        <div className="parent">
            <h4 className="head-90">Education</h4>
            {props.education.map((school, index) => 
                <div className="white-box row exp entry" key={index}>
                    <div className="col-md-12">
                        <h5>{school.institution}&nbsp;
                            <span><Moment format="MM/YYYY">{new Date(school.startDate)}</Moment> &nbsp;to&nbsp; {school.endDate !== "Present" ? ( <Moment format="MM/YYYY">{new Date(school.endDate)}</Moment> ) : (school.endDate)}</span>
                        </h5>
                        <div>
                            <strong>{school.studyType}</strong>
                        </div>
                        <div>
                            <span>{school.area}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )

}

export default Education;