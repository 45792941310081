import React from 'react';


const Header = (props) => {
    return (
        <div className="col-lg-4 pad-left sidebar">
            <div className="entry parent">
                <h4 className="head-90">Details</h4>
                <ul className="list-unstyled">
                    <li><strong>Phone</strong><br />
                        <span className="hidden-md-up d-lg-none"><a href="tel:{props.basics.phone}">{props.basics.phone}</a></span>
                        <span className="hidden-sm-down d-none d-lg-block">{props.basics.phone}</span></li>
                    <li><strong>E-Mail</strong><br /><a href="mailto:{props.basics.email}">{props.basics.email}</a></li>
                    <li><strong>www</strong><br /><a href="{props.basics.website}">{props.basics.website}</a></li>
                    <li>
                        <strong>Address</strong><br />
                        {props.basics.location.address}<br />
                        {props.basics.location.city}, {props.basics.location.region} {props.basics.location.postalCode}
                    </li>
                </ul>
            </div>

            <div className="entry parent">
                <h4 className="head-90">Social&nbsp;&nbsp;</h4>
                <ul className="list-unstyled">
                    {props.basics.profiles.map((profile, index) => <li key={index}><strong><i className={"fa fa-" + profile.network.toLowerCase() }aria-hidden="true"></i> {profile.network}</strong><br /><a href={profile.url}>{profile.url}</a></li>)}
                </ul>
            </div>

            <div className="entry parent">
                <h4 className="head-90">Skills&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                {props.skills.map((skill, index) => <div key={index}><h6>{skill.name}</h6><div className="m-bottom">{Object.keys(skill.keywords).join(", ")}</div></div>)}
            </div>

            <div className="text-center m-top">
                <a href={props.urls.repository} className="btn btn-outline-primary btn-sm" role="button" aria-disabled="true"><i className="fa fa-github" aria-hidden="true"></i> View Resume Source</a>
            </div>

            <hr />

            <div className="text-center m-top">
                <h6>Resume Downloads</h6>
                <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                    <a href={props.urls.word} className="btn btn-primary btn-sm">
                        <i className="fa fa-file-word-o" aria-hidden="true"></i> Word
                    </a>
                    <a href={props.urls.pdf} className="btn btn-primary btn-sm">
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i> PDF
                    </a>
                    <a href={props.urls.json} className="btn btn-primary btn-sm">
                        {'{}'} JSON
                    </a>
                </div>
            </div>
            {/* <div className="text-center m-top">
                <img src={props.logo} className="logos" alt="Made With React"/>
                <p>Made With React</p>
            </div> */}
        </div>
    )
}

export default Header;