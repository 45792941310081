import React, { Component } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Work from './components/Work';
import Education from './components/Education';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  constructor() {
      super();
      this.state = {
          data : null,
          urls: {
            repository: 'https://github.com/danmartin/resume',
            word: 'http://pdxdan.com/assets/docs/resume.docx',
            pdf: 'http://pdxdan.com/assets/docs/resume.pdf',
            json: 'http://pdxdan.com/assets/data/resume.json'
          },
          show_array: [],
          loading: true
      }
  }

  async componentDidMount() {
    const response = await fetch(`/resume.json`);
    const json = await response.json();
    this.setState({
      data : json,
      loadng: false
     });
  }

  render() {
    if(this.state.data === null){
      return (<header><h1>Loading...</h1></header>)
    } else {
      return (
        <div className="App">
          <Header basics={this.state.data.basics} />
          <div className="row">
            <div className="col-lg-8  pad-left">
              <Work work={this.state.data.work} show_array={this.state.show_array} loading={this.state.loading} />
              <Education education={this.state.data.education} />
            </div>
            <Sidebar basics={this.state.data.basics} skills={this.state.data.skills} urls={this.state.urls} logo={logo} />
          </div>
        </div>
      );
    }
  }
}

export default App;
